import styled from "styled-components";

export const ContactsContainer = styled.div`
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0d0d0d;
  position: relative;
  z-index: 4;
`;

export const ContactsWrapper = styled.div`
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  margin-top: 2em;
  align-items: center;
  grid-gap: 16px;
  padding: 0 30px;
  z-index: 1;
  position: relative;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 50px;
    max-width: 100%;
    min-height: 100vh;
  }
`;

export const ContactsColumn = styled.div`
  background: #2d2d2d;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  border-bottom: 3px solid #3da578;
  width: 100%;
  height: 100%;
  padding: 30px;
  min-height: 318px;
  box-shadow: rgba(255, 255, 255, 0.03) 15px 15px 20px,
    rgba(255, 255, 255, 0.09) 0px 6px 6px;
  transition: all 0.2s ease-in-out;
  z-index: 2;
  position: relative;
`;

export const ContactsH1 = styled.h1`
  font-size: clamp(1rem, 10vw, 3rem);
  text-transform: uppercase;
  color: #b3b3b3;
  margin-bottom: 64px;
  font-weight: 200;
  z-index: 4;
  position: relative;
`;
export const ContactsH3 = styled.h3`
  font-size: clamp(0.8rem, 10vw, 1.5rem);
  color: #b3b3b3;
  text-align: center;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
`;

export const ContactsTitle = styled.h1`
  font-size: clamp(0.6rem, 0.6rem + 5vw, 1.5rem);
  color: #3da578;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const ContactsInfo = styled.p`
  font-weight: 600;
  font-size: clamp(0.8em, 10vw, 1.2em);
  color: #b3b3b3;
  text-align: center;
  margin-top: 7px;
`;

export const ContactsEmail = styled.img`
  margin-top: 7px;
  padding-top: 5px;
  max-width: 90%;
  max-height: 3em;
  display: flex;
  color: #b3b3b3;
`;

export const ContactsTwitter = styled.p`
  color: #b3b3b3;
`;

export const ContactsPhoto = styled.img`
  width: 130px;
  margin: 0 0 10px 0;
  padding-top: 30px;
  margin-top: auto;
`;

export const CopyButton = styled.img`
  padding-left: 3px;
  max-width: 17px;
  cursor: pointer;

  &:active {
    max-width: 19px;
    margin-left: -2px;
  }
`;

export const EmailPhoto = styled.img`
  position: relative;
  align-items: center;
  margin-inline: auto;
  max-width: 400px;
  margin-bottom: 64px;
  z-index: 11;
  @media screen and (max-width: 768px) {
    max-width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
`;
