import React from "react";
import {
  Columnless,
  ColumnlessTitle,
  ColumnlessH1,
  ColumnlessP,
  ColumnlessImg,
  InfoRow,
  Column1,
  Column2,
  ColumnlessA,
} from "./MainElements";
import logo from "../../images/geoloc_padded_color_icon.png";
import logo2 from "../../images/geoloc_padded_color_o_icon.png";
import FadeInSection from "../Fader/index";

const MainSection = () => {
  return (
    <>
      <Columnless id="miksi">
        <FadeInSection><ColumnlessH1>KUNNALLE TUNTEMATTOMIEN RAKENNUSTEN KARTTAPALVELU</ColumnlessH1></FadeInSection>
        <InfoRow>
          <Column1>
            <FadeInSection><ColumnlessImg className="logo2" src={logo2} /></FadeInSection>
            <ColumnlessTitle>MIKSI</ColumnlessTitle>
            <FadeInSection><ColumnlessH1>Luvattomat rakennukset</ColumnlessH1></FadeInSection>
            <FadeInSection><ColumnlessP>
              Kunnissa on suhteellisen paljon raken&shy;nuksia, jotka ovat luvattomia syystä tai toisesta. Osasta näistä rakennuksista ei voida enää kiinteistö&shy;veroja kerätä, mutta verotus&shy;arvollisia rakennuksia löytyy kuitenkin merkittävästi.
            </ColumnlessP></FadeInSection>
            <FadeInSection><ColumnlessP>
              Ilmakuvien hyödyn&shy;täminen näiden raken&shy;nusten löytämisessä on keskeisessä osassa, mutta työ on usein <ColumnlessA href="https://yle.fi/uutiset/3-12452949" target="_blank">käsin tehtävää ja aikaa vievää</ColumnlessA>. Maanmittaus&shy;laitoksen ja yhteistyö&shy;kumppanei&shy;den tekemän <ColumnlessA href="https://www.maanmittauslaitos.fi/ajankohtaista/kmtk-rakennustiedot-voivat-toimia-kuntien-kiinteistoveroselvityksen-lahtotietoina" target="_blank">selvityksen</ColumnlessA> perusteella myös kansallisen maasto&shy;tieto&shy;kannan aineistoa voidaan luotet&shy;tavasti hyödyntää tuntemattomien rakennusten tunnistamisessa.
            </ColumnlessP></FadeInSection>
            <FadeInSection><ColumnlessP>
              Selvityksen perusteella verotuksen ulko&shy;puolelle jäävien rakennusten mediaani&shy;verotus&shy;arvo on noin <nobr>4600 €</nobr>. Vaasan kokoisella alueella lukema tarkoittaa noin <nobr>700 000 €</nobr> verottamatta jäänyttä vuotuista kiinteistö&shy;veroa. Pienehkössä keski&shy;suomalai&shy;sessa kunnassa vero&shy;tuloja jää kunnalta saamatta noin <nobr>125 000 €</nobr> vuodessa.
            </ColumnlessP></FadeInSection>
          </Column1>
          <Column2>
            <FadeInSection><ColumnlessImg src={logo} /></FadeInSection>
            <ColumnlessTitle>MITEN</ColumnlessTitle>
            <FadeInSection><ColumnlessH1>Automaattinen tunnistaminen</ColumnlessH1></FadeInSection>
            <FadeInSection><ColumnlessP>
              Palvelu tunnistaa luvattomat raken&shy;nukset automaat&shy;tisesti koko kunnan alueella. Tunnistetut rakennukset esitetään sel&shy;keässä ja helppo&shy;käyttöisessä kartta&shy;näkymässä. Palvelu edesauttaa tarkas&shy;tus&shy;käyntien suun&shy;nittelua ja tuntemat&shy;tomien raken&shy;nusten tutkintaa satelliittikuvista.
            </ColumnlessP></FadeInSection>
            <FadeInSection><ColumnlessP>
              Tunnista&shy;misessa hyödynnetään Maan&shy;mittaus&shy;laitoksen yllä&shy;pitämää kansallista maasto&shy;tieto&shy;kantaa, johon ilma&shy;kuvauksin havaitut rakennukset tallen&shy;netaan. Ilma&shy;kuvauksia tehdään <ColumnlessA href="https://tilannekartta.maanmittauslaitos.fi/ipa" target="_blank">noin kolmen vuoden sykleissä</ColumnlessA>. Mikäli havaittu rakennus on viran&shy;omaisten tiedossa, siihen liitetään väestö&shy;tieto&shy;järjestelmän pysyvä rakennus&shy;tunnus. Tätä prosessia kutsutaan <ColumnlessA href="https://tilannekartta.maanmittauslaitos.fi/prt" target="_blank">PRT-linkitykseksi</ColumnlessA>.
            </ColumnlessP></FadeInSection>
            <FadeInSection><ColumnlessP>
              Linkityksen ohella raken&shy;nuksia suoda&shy;tetaan myös kunnan Maanmittaus&shy;laitokselle toimittamien tietojen perusteella kunnalle jo tunnettujen rakennusten osalta. Mikäli rakennuk&shy;sella ei ole PRT-linkitystä eikä rakennus&shy;tietoja löydy kunnan toimittamana, rakennus luokitellaan kunnalle tuntemattomaksi ja esitetään karttanäkymässä.
            </ColumnlessP></FadeInSection>
          </Column2>
        </InfoRow>
      </Columnless>
    </>
  );
};

export default MainSection;
