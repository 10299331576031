import React from "react";
import petteri from "../../images/petteri.png";
import laurim from "../../images/laurim.png";
import laurih from "../../images/laurih.png";
import email from "../../images/email.png";
import FadeInSection from "../Fader/index";
import {
  ContactsColumn,
  ContactsContainer,
  ContactsTitle,
  ContactsWrapper,
  ContactsInfo,
  ContactsEmail,
  ContactsPhoto,
  ContactsH1,
  ContactsH3,
} from "./ContactsElements";

const ContactsSection = () => {
  return (
    <>
      <ContactsContainer id="yhteystiedot">
        <FadeInSection>
          <ContactsH1>Yhteystiedot</ContactsH1>
        </FadeInSection>
        <FadeInSection>
          <ContactsH3>
            Geoloc Oy tarjoaa helpotusta rakennusvalvonnan taakkaan.
          </ContactsH3>
        </FadeInSection>
        <FadeInSection>
          <ContactsH3>Ota yhteyttä ja kysy lisätietoja:</ContactsH3>
        </FadeInSection>
        <ContactsWrapper>
          <FadeInSection>
            <ContactsColumn>
              <ContactsTitle>Petteri Ranta</ContactsTitle>
              <ContactsInfo>Toimitusjohtaja</ContactsInfo>
              <ContactsEmail src={email}></ContactsEmail>
              <ContactsPhoto src={petteri} />
            </ContactsColumn>
          </FadeInSection>
          <FadeInSection>
            <ContactsColumn>
              <ContactsTitle>Lauri Mäkinen</ContactsTitle>
              <ContactsInfo>Hallituksen puheenjohtaja</ContactsInfo>
              <ContactsPhoto src={laurim} />
            </ContactsColumn>
          </FadeInSection>
          <FadeInSection>
            <ContactsColumn>
              <ContactsTitle>Lauri Huttunen</ContactsTitle>
              <ContactsInfo>Hallituksen jäsen</ContactsInfo>
              <ContactsPhoto src={laurih} />
            </ContactsColumn>
          </FadeInSection>
        </ContactsWrapper>
      </ContactsContainer>
    </>
  );
};

export default ContactsSection;
