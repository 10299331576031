import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #111;
  position: relative;
  z-index: 3;
  height: 100%;
  padding: 30px;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const RightsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none !important;
  }
`;

export const WebsiteRights = styled.h1`
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

export const Verse = styled.p`
  align-items: center;
  justify-content: center;
  width: 24em;
  text-align: center;
  margin-top: 1em;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.15);
`;
