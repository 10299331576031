export const SliderDataPublic = [
  { image: "/images/demo-1.jpg" },
  { image: "/images/demo-2.jpg" },
  { image: "/images/demo-3.2.png" },
  { image: "/images/demo-4.jpg" },
  { image: "/images/demo-5.jpg" },
];

export const SliderDataPublicMobiili = [
  { image: "/images/demo-1-mobiili.jpg" },
  { image: "/images/demo-2-mobiili.jpg" },
  { image: "/images/demo-3-mobiili.png" },
  { image: "/images/demo-4-mobiili.png" },
  { image: "/images/demo-5-mobiili.jpg" },
];

export const SliderDataTitles = [
  { title: "Karttanäkymä" },
  { title: "Havaintojen valinta" },
  { title: "Satelliittikuvat" },
  { title: "Tietojen vienti" },
  { title: "Tarkastustiedot" },
];

export const SliderDataTexts = [
  {
    text: "Kunnalle tuntemattomat rakennukset esitettynä intuitiivisessa karttanäkymässä.\nHavainnot ryhmitellään automaattisesti zoomaustason mukaan.",
  },
  {
    text: "Kiinnostavia kohteita voi valita tarkasteluun yksitellen ja aluerajauksella.\nHavainnoista on saatavilla pohjapinta-ala, kiinteistötunnus, osoite ja havaintopäivämäärä.",
  },
  {
    text: "Kohteita on myös mahdollista esitarkastaa satelliittikuvista.\nNapin painallus avaa satelliittikuvan sen hetkisestä karttanäkymästä uuteen välilehteen.",
  },
  {
    text: "Valittujen kohteiden tietojen vienti Excel-tiedostoon sisäänrakennettuna ominaisuutena.\nRakennustarkastusten suunnittelu onnistuu helpommin osissa.",
  },
  {
    text: "Tarkastuksen jälkeen kohteen voi lopuksi merkitä tarkastetuksi.\nTarkastuksen yhteydessä tallennetaan karkea tieto kohteen arvosta.",
  },
];
