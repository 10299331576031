import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  RightsWrapper,
  WebsiteRights,
  Verse
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <RightsWrapper>
          <WebsiteRights to="/" onClick={toggleHome}>
            Geoloc Oy © {new Date().getFullYear() === 2022 ? '2022' : '2022-' + new Date().getFullYear()} All rights reserved
          </WebsiteRights>
        </RightsWrapper>
        <Verse>
          Toimikaa sen kaupungin parhaaksi, johon minä olen teidät siirtänyt. Rukoilkaa sen puolesta Herraa, sillä sen menestys on teidänkin menestyksenne. Jer. 29:7
        </Verse>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
