import React from 'react'
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
} from "./SidebarElements"

const Sidebar = ({isOpen, toggle}) => {

  return (
    <SidebarContainer isOpen = {isOpen} onClick = {toggle}>
        <Icon onClick = {toggle}>
            <CloseIcon onClick = {toggle}/>
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>
                <SidebarLink to="miksi" onClick = {toggle} offset={-80}>Ajatus</SidebarLink>
                <SidebarLink to="kartta" onClick = {toggle} offset={-80}>Kartta</SidebarLink>
                <SidebarLink to="yhteystiedot" onClick = {toggle} offset={-80}>Yhteystiedot</SidebarLink>
            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar