import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";
import bgimage2 from "../../images/background2.png";

export const HeroContainer = styled.div`
  min-height: 100vh;
  background-color: #202020;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  position: relative;
  z-index: 1;
`;

export const HeroBg = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  min-height: 100%;
  background-image: url(${bgimage2});
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 80%;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.img`
  color: #fff;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 2rem;
  align-items: center;
  max-width: 70vw;
  max-height: 280px;
`;
export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: clamp(0.6rem, 10vw, 1.6rem);
  text-align: center;
  max-width: 70vw;
  pointer-events: none;
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: auto;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: auto;
  font-size: 20px;
`;
