import styled from "styled-components";

export const MainContainer = styled.div`
  background-color: black;
`;

export const Columnless = styled.div`
  padding-top: 5em;
  min-height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #000;
  flex-direction: column;
  background-position: center;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 1000px) {
    min-height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;

export const ColumnlessTitle = styled.h1`
  width: 100%;
  color: #3da578;
  font-size: clamp(1rem, 10vw, 2rem);
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 40px;
  justify-content: center;
`;

export const ColumnlessH1 = styled.h1`
  color: #b3b3b3;
  font-size: clamp(1rem, 10vw, 3rem);
  width: 100%;
  font-weight: 200;
  margin-bottom: 40px;
  text-align: center;
`;

export const ColumnlessP = styled.p`
  color: #b3b3b3;
  text-align: justify;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  @media screen and (max-width: 1000px) {
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
    font-size: 1.1rem;
  }
`;

export const ColumnlessA = styled.a`
  color: #3da578;
  font-size: 1.3rem;
  @media screen and (max-width: 1000px) {
    font-size: 1.1rem;
  }
`;

export const ColumnlessImg = styled.img`
  max-width: 200px;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  max-width: 80vw;
  align-items: top;
  grid-template-areas: ${({ imgStart }) =>
  imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 1000px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    max-width: 90vw;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 15px;
  max-width: 100%;
  grid-area: col1;

  @media screen and (max-width: 1000px) {
    padding-bottom: 100px;
  }
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 15px;
  max-width: 100%;
  grid-area: col2;

  @media screen and (max-width: 1000px) {
    padding-bottom: 100px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
