import React from "react";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column2,
  ImgWrap,
  Img,
  ImageButtonWrap,
  ImageButton,
  ButtonText,
  ButtonTextMobile,
  ImgWrapMobile,
  MobileSliderCount,
  Title,
  ButtonTextMobileWrap,
  TitleH1,
  ImageButtonMobile,
  SelectButtonWrap,
  MobiiliWrap,
} from "./InfoElements";
import {
  SliderDataPublic,
  SliderDataPublicMobiili,
  SliderDataTitles,
  SliderDataTexts,
} from "../ImageSlider/SliderData";
import { SlArrowRight } from "react-icons/sl";
import FadeInSection from "../Fader";

const InfoSection = () => {
  const [currentImage, setCurrentImage] = React.useState(0);

  const length = SliderDataPublic.length;

  const nextSlide = () => {
    setCurrentImage(currentImage === length - 1 ? 0 : currentImage + 1);
  };

  const selectImage = (index) => {
    setCurrentImage(index);
  };

  return (
    <InfoContainer id="kartta">
      <InfoWrapper>
        <FadeInSection>
          <TitleH1>KARTTAPALVELU</TitleH1>
        </FadeInSection>
        <Title>OMINAISUUDET</Title>
        <FadeInSection>
          <InfoRow>
            <Column2>
              <ImageButtonWrap>
                {SliderDataTitles.map((title, index) => {
                  return (
                    <div className="button-wrapper" key={index}>
                      <ImageButton
                        className={
                          currentImage === 0 ? "slider-button" : "off-button"
                        }
                        onClick={() => selectImage(index)}
                      >
                        {title.title}
                      </ImageButton>
                      {SliderDataTexts.map((text, index2) => {
                        if (currentImage === index2 && index === index2)
                          return (
                            <ButtonText key={index2}>{text.text}</ButtonText>
                          );
                        else {
                          return null;
                        }
                      })}
                    </div>
                  );
                })}
              </ImageButtonWrap>
              <FadeInSection>
                <ImgWrap>
                  {SliderDataPublic.map((slide, index) => {
                    return (
                      <div
                        className={
                          index === currentImage ? "slide active" : "slide"
                        }
                        key={index}
                      >
                        {index === currentImage && (
                          <Img
                            onClick={nextSlide}
                            src={slide.image}
                            alt="demot"
                            className="image"
                          />
                        )}
                      </div>
                    );
                  })}
                </ImgWrap>
              </FadeInSection>
              <MobiiliWrap>
                <FadeInSection>
                  <ButtonTextMobileWrap>
                    {SliderDataTitles.map((title, index) => {
                      if (currentImage === index) {
                        return (
                          <div className="button-wrapper" key={index}>
                            <ImageButtonMobile
                              id="kuva"
                              to="kuva"
                              smooth={true}
                              duration={500}
                              isDynamic={true}
                              exact="true"
                              offset={-80}
                              onClick={nextSlide}
                            >
                              {title.title}{" "}
                              <SlArrowRight
                                onClick={nextSlide}
                                className="rightarrow"
                              ></SlArrowRight>
                            </ImageButtonMobile>
                            {SliderDataTexts.map((text, index2) => {
                              if (currentImage === index2 && index === index2)
                                return (
                                  <ButtonTextMobile key={index2}>
                                    {text.text}
                                  </ButtonTextMobile>
                                );
                              else {
                                return null;
                              }
                            })}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </ButtonTextMobileWrap>
                </FadeInSection>
                <FadeInSection>
                  <ImgWrapMobile id="mobiili-kuva">
                    {SliderDataPublicMobiili.map((slide, index) => {
                      return (
                        <div
                          className={
                            index === currentImage
                              ? "slide-mobile active"
                              : "slide-mobile"
                          }
                          key={index}
                        >
                          {index === currentImage && (
                            <SelectButtonWrap>
                              <Img
                                src={slide.image}
                                alt="demot"
                                className="image"
                                onClick={nextSlide}
                              />
                            </SelectButtonWrap>
                          )}
                        </div>
                      );
                    })}
                  </ImgWrapMobile>
                </FadeInSection>
              </MobiiliWrap>
            </Column2>
          </InfoRow>
        </FadeInSection>
        <MobileSliderCount onClick={nextSlide}>
          {(currentImage + 1).toString() + "/" + SliderDataPublicMobiili.length}
        </MobileSliderCount>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default InfoSection;
