import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? "#0f0f0f" : "transparent")};
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogoContainer = styled.div`
  display: flex;
  max-width: 100px;
  justify-content: center;
  margin-right: auto;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none !important;
  }
`;

export const NavLogoImage1 = styled.img`
  max-width: 100%;
  padding-left: 0px;

  @media screen and (min-width: 768px) {
    animation: defaultBrightness 0.4s forwards;
    &:hover {
      animation: brightnessChange 0.4s forwards;
    }

    @keyframes defaultBrightness {
      100% {
        filter: brightness(1);
      }
      0% {
        filter: brightness(1.5);
      }
    }

    @keyframes brightnessChange {
      0% {
        filter: brightness(1);
      }
      100% {
        filter: brightness(1.5);
      }
    }
  }
`;

export const NavLogoImage2 = styled.img`
  max-width: 100%;
  padding-left: 0px;
`;

export const NavLogo = styled(LinkS)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5 rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:focus {
      outline: none !important;
    }
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;
export const NavLinks = styled(LinkS)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-weight: 600;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none !important;
  }

  animation: defaultColor 0.4s forwards;

  &:hover {
    animation: colorChange 0.4s forwards;
  }

  @keyframes defaultColor {
    100% {
      color: #fff;
    }
    0% {
      color: #3da578;
    }
  }

  @keyframes colorChange {
    0% {
    }
    100% {
      color: #3da578;
    }
  }

  &.active {
    border-bottom: 3px solid #3da578;
    border-radius: 2px;
    -webkit-transition: all ease-out 0.05s;
    -moz-transition: all ease-out 0.05s;
    -o-transition: all ease-out 0.05s;
    transition: all ease-out 0.05s;
    color: #3da578;
  }
`;
