import React from "react";
import { Button } from "../ButtonElements";
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import wordmark from "../../images/geoloc_color_white_wordmark.png";

const HeroSection = () => {
  const [hover, setHover] = React.useState(false);

  const onHover = () => {
    setHover((prevHover) => !prevHover);
  };
  const [hover2, setHover2] = React.useState(false);

  const onHover2 = () => {
    setHover2((prevHover2) => !prevHover2);
  };

  return (
    <HeroContainer>
      <HeroBg></HeroBg>
      <HeroContent>
        <HeroH1 src={wordmark}></HeroH1>
        <HeroP>{`ja kunta menestyy`}</HeroP>
        <HeroBtnWrapper>
          <Button
            to="miksi"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Lue lisää {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
          <Button
            to="yhteystiedot"
            onMouseEnter={onHover2}
            onMouseLeave={onHover2}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Ota yhteyttä {hover2 ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
