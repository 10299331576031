import styled from "styled-components";
import { Link as LinkS } from "react-scroll";

export const InfoContainer = styled.div`
  padding-top: 3em;
  min-height: 100vh;
  padding-bottom: 100px;
  max-width: 100vw;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #202020;
  background-position: center;
  position: "";
  z-index: 2;

  @media screen and (max-width: 768px) {
    padding: 2em 0;
    max-height: 300vh;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  max-width: 100%;
  max-width: 1600px;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  justify-content: center;

  @media screen and (max-width: 768px) {
    max-width: 100vw;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(1fr);
  max-width: 100%;
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    max-width: 100%;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  grid-area: col1;
  @media screen and (max-width: 768px) {
    background-color: none;
  }
`;

export const TextWrapper = styled.div`
  max-width: 100vw;
  padding-top: 0;
  padding-bottom: 60px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const TopLine = styled.h1`
  color: #3da578;
  font-size: 2rem;

  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  letter-spacing: 0.7rem;
`;

export const Heading = styled.h1`
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  font-size: 3rem;
  line-height: 1.1;
  font-weight: 200;
  color: "#b3b3b3";

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 100%;
  margin-bottom: 35px;
  font-size: 1.3rem;
  line-height: 24px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #b3b3b3;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  max-width: 100%;
  grid-area: col2;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImgWrap = styled.div`
  max-width: 90%;
  display: flex;
  align-items: right;
  justify-content: center;
  z-index: 2;
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ImgWrapMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-inline: auto;
    display: flex;
    align-items: right;
    justify-content: center;
  }
`;

export const SelectButtonWrap = styled.div`
  height: inherit;
  width: inherit;
`;

export const Img = styled.img`
  position: relative;
  max-width: 100%;
  margin-inline: auto;
  padding-right: 0;
  border-radius: 5px;
  max-height: 80vh;
  cursor: pointer;
  opacity: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    outline: none !important;
  }

  &:hover {
    transform: scale(1.001);
    transition: all 0.2s ease-in-out;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    border-radius: 0px;
    max-height: 100vh;
    z-index: 99;
  }
`;

export const ImageButtonWrap = styled.div`
  max-width: 20%;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 40px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding-right: 0px;
  }
`;

export const ImageButton = styled.div`
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #46bd8a 0,
    #3da578 100%
  );
  border: 0;

  box-shadow: rgba(61, 165, 120, 0.4) 0 2px 4px,
    rgba(61, 165, 120, 0.3) 0 7px 13px -3px,
    rgba(61, 165, 120, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-weight: 1000;
  margin-bottom: 5px;
  border-radius: 4px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
  width: 100%;
  z-index: 100;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    box-shadow: #3da578 0 0 0 1.5px inset, rgba(61, 165, 120, 0.4) 0 2px 4px,
      rgba(61, 165, 120, 0.3) 0 7px 13px -3px, #3da578 0 -3px 0 inset;
    outline: none !important;
  }

  &:hover {
    box-shadow: rgba(61, 165, 120, 0.4) 0 4px 8px,
      rgba(61, 165, 120, 0.3) 0 7px 13px -3px, #3da578 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: #3da578 0 3px 7px inset;
    transform: translateY(2px);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ButtonText = styled.p`
  position: relative;
  min-height:150px;
  font-size: 1rem;
  font-weight: 600;
  animation: fadeIn 0.7s;
  width: 100%;
  padding: 10px;
  text-align: left;
  background-color: rgba(25, 65, 48, 0.7);
  border-radius: 5px;
  z-index: 10;
  margin-top: -8px;
  margin-inline: auto;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }
`;

export const ButtonTextMobile = styled.p`
  display: none;

  @media screen and (max-width: 768px) {
    font-size: clap(0.7rem, 1rem + 10vh, 1.6rem);
    animation: fadeIn 0.7s;
    padding: 10px;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: rgba(61, 165, 120, 0.3);
    z-index: 100;
    display: flex;
    max-width: 100vw;
    overflow: hidden;
    margin-bottom: auto;
  }

  @keyframes fadeIn {
    0% {
      transform: translateY(-52px);
      opacity: 0;
    }

    100% {
      transform: translateY(0px);
      opacity: 100%;
    }
  }
`;

export const MobileSliderCount = styled.p`
  margin: auto;
  margin-top: -40px;
  padding: 0.4em;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  display: flex;
  border-radius: 10px;
  color: #fff;
  position: relative;
  background-color: #3da578;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const Title = styled.h1`
  width: 100%;
  color: #3da578;
  font-size: clamp(1rem, 10vw, 2rem);
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 60px;
  justify-content: center;
  z-index: 100;
`;

export const TitleH1 = styled.h1`
  color: #b3b3b3;
  font-size: clamp(1rem, 10vw, 3rem);
  width: 100%;
  font-weight: 200;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
`;

export const ButtonTextMobileWrap = styled.div`
  max-width: 100%;
  min-height: 100%;
  z-index: 99;
`;

export const ImageButtonMobile = styled(LinkS)`
  align-items: center;
  appearance: none;
  background-image: radial-gradient(
    100% 100% at 100% 0,
    #46bd8a 0,
    #3da578 100%
  );
  border: 0;

  box-shadow: rgba(61, 165, 120, 0.4) 0 2px 4px,
    rgba(61, 165, 120, 0.3) 0 7px 13px -3px,
    rgba(61, 165, 120, 0.5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-weight: 600;

  height: 52px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
  width: 100%;
  z-index: 9;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:focus {
    box-shadow: #3da578 0 0 0 1.5px inset, rgba(61, 165, 120, 0.4) 0 2px 4px,
      rgba(61, 165, 120, 0.3) 0 7px 13px -3px, #3da578 0 -3px 0 inset;
    outline: none !important;
  }

  &:hover {
    box-shadow: rgba(61, 165, 120, 0.4) 0 4px 8px,
      rgba(61, 165, 120, 0.3) 0 7px 13px -3px, #3da578 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: #3da578 0 3px 7px inset;
    transform: translateY(2px);
  }

  @media screen and (min-width: 768px) {
    display: none;
  }

  animation: fadeInArrow 0.3s;

  @keyframes fadeInArrow {
    0% {
      color: rgba(255, 255, 255, 0.1);
    }
    100% {
      color: rgba(255, 255, 255, 1);
    }
  }
`;

export const MobiiliWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
